import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import StatusComponent from "atoms/StatusComponent";
import TableCellContent from "atoms/TableCellContent";
import config from "config";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { LIMIT_FILE_STYLES, LIMIT_REQUEST_APPLICATION_STATUS, LIMIT_REQUEST_TYPES } from "global/constants";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import tenantConfig from "tenantConfig";
import colors from "theme/colors";
import { numberWithDots } from "utils/helpers";

export function getTimeStamp(type, updatedAt) {
  return type === "create"
    ? dayjs().format("ddd, DD MMM YYYY HH:mm:ss") // Current date in JKT timezone
    : dayjs(updatedAt).format("ddd, DD MMM YYYY HH:mm:ss"); // createdAt in JKT timezone
}

function TableLimitDecision({
  requestType,
  reqId,
  mandatoryDocsReviewStatus = LIMIT_REQUEST_APPLICATION_STATUS.PENDING,
  additionalDocReviewStatus = LIMIT_REQUEST_APPLICATION_STATUS.PENDING,
  finalDecisionStatus = LIMIT_REQUEST_APPLICATION_STATUS.PENDING,
  updatedAt,
  createdAt,
}) {
  const { values } = useFormikContext();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type"); // "0", "1", or "2"
  const { t } = useTranslation("common");
  const { currencySymbol } = tenantConfig[config.COUNTRY_CODE];
  const requestId = t("limitRequest.decisionList.requestId");
  const requestTypeText = t("limitRequest.decisionList.requestType");
  const requestDate = t("limitRequest.decisionList.requestDate");
  const requestEWALimit = t("limitRequest.decisionList.requestEWALimit");
  const requestSDLimit = t("limitRequest.decisionList.requestSDLimit");
  const mandatoryDocumentDecision = t("limitRequest.decisionList.mandatoryDocumentDecision");
  const additionalDocumentDecision = t("limitRequest.decisionList.additionalDocumentDecision");
  const finalDecision = t("limitRequest.decisionList.finalDecision");
  const totalEWARequestedLimit = t("limitRequest.decisionList.totalEWARequestedLimit");
  const totalSDRequestedLimit = t("limitRequest.decisionList.totalSDRequestedLimit");
  const totalEWApprovedLimit = t("limitRequest.decisionList.totalEWApprovedLimit");
  const totalSDApprovedLimit = t("limitRequest.decisionList.totalSDApprovedLimit");
  const lastUpdatedAt = t("limitRequest.decisionList.lastUpdatedAt");
  const requestName = LIMIT_REQUEST_TYPES.filter((item) => item.id?.toString() === requestType)[0]?.name;
  return (
    <TableContainer
      style={{
        marginTop: 24,
      }}
      component={Paper}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead
          style={{
            backgroundColor: colors.neutral.mist,
          }}
        >
          <TableRow>
            <TableCellContent variant="xs-bold" align="center">
              Details
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="center">
              Status
            </TableCellContent>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestId}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {reqId}
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestTypeText}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {requestName}
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestDate}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {getTimeStamp(type, createdAt)}
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestEWALimit}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {`${currencySymbol}${numberWithDots(values?.ewaLimit ?? 0)}`}
            </TableCellContent>
          </TableRow>

          {["1", "2"].includes(requestType) && (
            <TableRow>
              <TableCellContent variant="xs-bold" align="left">
                {requestSDLimit}
              </TableCellContent>
              <TableCellContent variant="xs-bold" align="left">
                {`${currencySymbol}${numberWithDots(values?.sdLimit ?? 0)}`}
              </TableCellContent>
            </TableRow>
          )}

          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {mandatoryDocumentDecision}{" "}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              <StatusComponent status={mandatoryDocsReviewStatus} arrayToCheck={LIMIT_FILE_STYLES} />
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {additionalDocumentDecision}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              <StatusComponent status={additionalDocReviewStatus} arrayToCheck={LIMIT_FILE_STYLES} />
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {finalDecision}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              <StatusComponent status={finalDecisionStatus} arrayToCheck={LIMIT_FILE_STYLES} />
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {finalDecisionStatus === LIMIT_REQUEST_APPLICATION_STATUS.APPROVED
                ? totalEWApprovedLimit
                : totalEWARequestedLimit}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {`${currencySymbol}${numberWithDots(values?.totalEWALimit ?? 0)}`}
            </TableCellContent>
          </TableRow>
          {["1", "2"].includes(requestType) && (
            <TableRow>
              <TableCellContent variant="xs-bold" align="left">
                {finalDecisionStatus === LIMIT_REQUEST_APPLICATION_STATUS.APPROVED
                  ? totalSDApprovedLimit
                  : totalSDRequestedLimit}
              </TableCellContent>
              <TableCellContent variant="xs-bold" align="left">
                {`${currencySymbol}${numberWithDots(values?.totalSDLimit ?? 0)}`}
              </TableCellContent>
            </TableRow>
          )}
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {lastUpdatedAt}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {getTimeStamp(type, updatedAt)}
            </TableCellContent>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableLimitDecision;
