import { useFormikContext } from "formik";
import { LIMIT_FILE_STATUS } from "global/constants";
import { Button, Text, View } from "native-base";
import colors from "theme/colors";

const MandatoryTabActions = ({ requestType, setValue }) => {
  const { values, errors } = useFormikContext();

  // Check if mandatory docs are all uploaded
  const allMandatoryUploaded =
    Array.isArray(values.mandatoryDocuments) &&
    values.mandatoryDocuments.every(
      (doc) => !(doc.status === LIMIT_FILE_STATUS.AWAITING_UPLOAD || doc.status === LIMIT_FILE_STATUS.NEEDS_CHECK),
    );

  // Check limit validity
  let limitsValid = true;
  if (requestType === "0" || requestType === "2") {
    // EWA required
    limitsValid = limitsValid && values.ewaLimit !== null && values.ewaLimit !== "" && !errors.ewaLimit;
  }
  if (requestType === "1" || requestType === "2") {
    // SD required
    limitsValid = limitsValid && values.sdLimit !== null && values.sdLimit !== "" && !errors.sdLimit;
  }

  // Check if reason is provided and valid
  const reasonValid = values.reason && values.reason.trim().length > 0 && !errors.reason;

  // Determine if the user can proceed
  const canProceed = allMandatoryUploaded && limitsValid && reasonValid;

  const handleNext = () => {
    setValue(1);
  };

  return (
    <View marginTop="16px">
      <Button
        onPress={handleNext}
        disabled={!canProceed}
        style={{ backgroundColor: canProceed ? colors.secondary.orchidBlue : colors.neutral.darkMist }}
      >
        <Text variant="sm-bold" color={colors.neutral.cotton}>
          Next
        </Text>
      </Button>
    </View>
  );
};

export default MandatoryTabActions;
